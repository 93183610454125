import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { type Shift } from "@src/appV2/Shifts/Shift/types";
import { type ShiftInviteDetails } from "@src/appV2/Shifts/ShiftInvites/types";
import { differenceInMinutes, parseISO } from "date-fns";

export function logShiftInviteConflictEvent(
  shiftInviteId: string,
  conflictingShift: Shift,
  shiftInviteDetails: ShiftInviteDetails
) {
  const shiftStart = parseISO(conflictingShift.start);
  const shiftEnd = parseISO(conflictingShift.end);
  const shiftInviteStart = parseISO(shiftInviteDetails.start);
  const shiftInviteEnd = parseISO(shiftInviteDetails.end);
  logEvent(APP_V2_APP_EVENTS.SHIFT_INVITE_CONFLICTING_SHIFT_FOUND, {
    shiftInviteId,
    conflictingShift: {
      start: shiftStart,
      end: shiftEnd,
      facilityId: conflictingShift.facilityId,
      facilityName: conflictingShift.facility.name,
      shiftId: conflictingShift.shiftId,
    },
    shiftInvite: {
      start: shiftInviteStart,
      end: shiftInviteEnd,
    },
    timeDifference: {
      atStart: Math.abs(differenceInMinutes(shiftEnd, shiftInviteStart)),
      atEnd: Math.abs(differenceInMinutes(shiftStart, shiftInviteEnd)),
    },
    rawData: JSON.stringify({
      conflictingShift: {
        start: conflictingShift.start,
        end: conflictingShift.end,
        shiftId: conflictingShift.shiftId,
      },
      shiftInvite: {
        start: shiftInviteDetails.start,
        end: shiftInviteDetails.end,
      },
    }),
  });
}
