import { isDefined } from "@clipboard-health/util-ts";
import {
  areIntervalsOverlapping,
  differenceInMinutes,
  max as dateMax,
  min as dateMin,
  parseISO,
} from "date-fns";

import { type Shift } from "../Shift/types";
import { type ShiftInvite } from "./types";

const OVERLAP_THRESHOLD_MINUTES_AT_SAME_FACILITY = 30;

interface TimeInterval {
  start: Date;
  end: Date;
}

type ShiftTimeInterval = Pick<Shift, "start" | "end"> & {
  facilityId: string | undefined;
};

export function getOverlappingDurationInMinutes(
  interval1: TimeInterval,
  interval2: TimeInterval
): number {
  const overlapStart = dateMax([interval1.start, interval2.start]);
  const overlapEnd = dateMin([interval1.end, interval2.end]);

  if (overlapStart < overlapEnd) {
    return differenceInMinutes(overlapEnd, overlapStart);
  }

  return 0; // No overlap
}

/**
 * Determines if two shifts overlap within the overlap threshold. We allow 30 minutes of overlap for shifts at the same facility when working double but no overlap for shifts at different facility
 * @param firstShift
 * @param secondShift
 * @returns True if the two shifts overlap within the overlap threshold, false otherwise
 */
export function isShiftOverlapWithinThreshold(
  firstShift: ShiftTimeInterval,
  secondShift: ShiftTimeInterval
) {
  const start = parseISO(firstShift.start);
  const end = parseISO(firstShift.end);
  const proposedStart = parseISO(secondShift.start);
  const proposedEnd = parseISO(secondShift.end);

  if (!areIntervalsOverlapping({ start: proposedStart, end: proposedEnd }, { start, end })) {
    return true;
  }

  if (
    isDefined(firstShift.facilityId) &&
    isDefined(secondShift.facilityId) &&
    firstShift.facilityId !== secondShift.facilityId
  ) {
    return false;
  }

  const overlapDurationInMinutes = getOverlappingDurationInMinutes(
    { start, end },
    { start: proposedStart, end: proposedEnd }
  );

  return overlapDurationInMinutes <= OVERLAP_THRESHOLD_MINUTES_AT_SAME_FACILITY;
}

/**
 * Given a proposed shift time and existing shifts, returns the first conflicting shift.
 */
export function getConflictingShift(
  shifts: Shift[],
  proposedShift: ShiftTimeInterval
): Shift | undefined {
  return shifts.find(
    (shift) =>
      !isShiftOverlapWithinThreshold(
        { ...shift, facilityId: shift.facilityId ?? shift.facility?.userId },
        proposedShift
      )
  );
}

function mapInviteToShift(shiftInvite: ShiftInvite): ShiftTimeInterval {
  const {
    attributes: { shiftDetails, workplaceId },
  } = shiftInvite;
  return {
    start: shiftDetails.start,
    end: shiftDetails.end,
    facilityId: workplaceId,
  };
}

/**
 * Given a proposed shift and existing shift invites, returns all conflicting shift invites.
 */
export function getConflictingShiftInvitesWithShift(
  shiftInvites: ShiftInvite[],
  proposedShift: ShiftTimeInterval
): ShiftInvite[] {
  return shiftInvites.filter(
    (shiftInvite) => !isShiftOverlapWithinThreshold(mapInviteToShift(shiftInvite), proposedShift)
  );
}

export function getConflictingShiftInvites(
  shiftInvites: ShiftInvite[],
  proposedShiftInvite: ShiftInvite
): ShiftInvite[] {
  const proposedShift = mapInviteToShift(proposedShiftInvite);
  return getConflictingShiftInvitesWithShift(shiftInvites, proposedShift);
}
