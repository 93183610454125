import { facilitySchema } from "@src/appV2/Facilities/types";
import { BookabilityUnmetCriteria } from "@src/appV2/OpenShifts/ShiftAction/types";
import { z } from "zod";

export enum ShiftInviteStatus {
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
  DELETED = "DELETED",
  PENDING = "PENDING",
}

export const acceptOrDeclineShiftInviteRequestSchema = z.object({
  data: z.object({
    attributes: z.object({
      status: z.nativeEnum(ShiftInviteStatus),
    }),
    type: z.string(),
    id: z.string(),
  }),
});

const shiftInviteDetailsSchema = z.object({
  start: z.string(),
  end: z.string(),
  agentReq: z.string(),
  name: z.string(),
  tmz: z.string().optional(),
  pay: z.number(),
});

export type ShiftInviteDetails = z.infer<typeof shiftInviteDetailsSchema>;

export const shiftInviteSchema = z.object({
  attributes: z.object({
    workerId: z.string(),
    workplaceId: z.string(),
    shiftId: z.string().optional(),
    shiftDetails: shiftInviteDetailsSchema,
    expiresAt: z.string(),
    status: z.string().optional(),
    agent: z.object({
      name: z.string(),
      userId: z.string(),
      profileImageUrl: z.string().optional(),
    }),
    facility: facilitySchema,
  }),
  id: z.string(),
  type: z.string(),
});

const missingDocumentSchema = z.object({
  name: z.string(),
  level: z.string(),
});

export type MissingDocument = z.infer<typeof missingDocumentSchema>;

export const shiftInvitesErrorSchema = z.object({
  status: z.number().optional(),
  detail: z.string().optional(),
  title: z.string().optional(),
  reasons: z.array(z.nativeEnum(BookabilityUnmetCriteria)).optional(),
  code: z.string().optional(),
  id: z.string().optional(),
  missingDocuments: z.array(missingDocumentSchema).nullish(),
});

const acceptOrDeclineParamsSchema = z.object({
  shiftInviteId: z.string(),
  status: z.union([z.literal(ShiftInviteStatus.ACCEPTED), z.literal(ShiftInviteStatus.DECLINED)]),
  isOverworkingShiftsAllowed: z.boolean().optional(),
  skipFacilityRequiredDocumentCheck: z.boolean().optional(),
});

export type AcceptOrDeclineParams = z.infer<typeof acceptOrDeclineParamsSchema>;

export type AcceptOrDeclineShiftInviteRequest = z.infer<
  typeof acceptOrDeclineShiftInviteRequestSchema
>;

export type ShiftInvite = z.infer<typeof shiftInviteSchema>;

export type ShiftInvitesError = z.infer<typeof shiftInvitesErrorSchema>;

export const shiftInvitesResponseSchema = z.object({
  data: z.array(shiftInviteSchema),
  errors: z.array(shiftInvitesErrorSchema).optional(),
});

export const acceptOrDeclineShiftInviteResponseSchema = z.object({
  data: z.object({
    attributes: z.object({
      workerId: z.string(),
      workplaceId: z.string(),
      shiftId: z.string().optional(),
      shiftDetails: shiftInviteDetailsSchema,
      expiresAt: z.string(),
      status: z.string(),
    }),
    id: z.string(),
    type: z.string(),
  }),
});

export const acceptOrDeclineShiftInviteErrorResponseSchema = z.object({
  errors: z.array(shiftInvitesErrorSchema).optional(),
});

export type AcceptOrDeclineShiftInviteResponse = z.infer<
  typeof acceptOrDeclineShiftInviteResponseSchema
>;

export type AcceptOrDeclineShiftInviteErrorResponse = z.infer<
  typeof acceptOrDeclineShiftInviteErrorResponseSchema
>;

export type ShiftInvitesResponse = z.infer<typeof shiftInvitesResponseSchema>;

export interface AcceptShiftInviteProps {
  shiftInvite: ShiftInvite;
  isOverworkingShiftsAllowed?: boolean;
  shouldDeclineConflictingInvites?: boolean;
  skipFacilityRequiredDocumentCheck?: boolean;
}
